<template>
  <v-container>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Board Documents Admin</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn to="/board" outlined exact>Board Member View</v-btn>
      </v-toolbar>
      <v-tabs v-model="selectedTab">
        <v-tab>Files</v-tab>
        <v-tab>Users</v-tab>
        <v-tab>Minutes</v-tab>
      </v-tabs>
      <v-tabs-items v-model="selectedTab">
        <v-tab-item>
          <file-list :college-access="collegeAccess" :foundation-access="foundationAccess"></file-list>
        </v-tab-item>
        <v-tab-item>
          <users :college-access="collegeAccess" :foundation-access="foundationAccess"></users>
        </v-tab-item>
        <v-tab-item>
          <minutes :college-access="collegeAccess" :foundation-access="foundationAccess"></minutes>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>
<script>
import { ref, computed, onMounted } from '@vue/composition-api'

export default {
  components: {
    fileList: () => import('@/components/board/admin/fileList'),
    users: () => import('@/components/board/admin/users'),
    minutes: () => import('@/components/board/admin/minutes')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const selectedTab = ref(0)
    const collegeAccess = ref(false)
    const foundationAccess = ref(false)

    onMounted(() => {
      if (user.value.roles.includes('Presidents Office Staff')) collegeAccess.value = true
      if (user.value.roles.includes('Development')) foundationAccess.value = true
      if (user.value.roles.includes('Technology Services')) {
        collegeAccess.value = true
        foundationAccess.value = true
      }
    })

    return {
      selectedTab,
      collegeAccess,
      foundationAccess
    }
  }
}
</script>
